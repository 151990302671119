<template>
    <div class="input-block">
        <TextInput
            divClass="form-block d-flex"
            inputId="name"
            inputClass="col-9"
            inputName=""
            inputType="text"
            :readonly="false"
            labelClass="col-2"
            labelName="端末名"
            size=""
            v-model="name"
            :error="''"
        />
        <TextArea
            divClass="form-block d-flex"
            inputId="free1"
            inputClass="col-9"
            inputName=""
            :readonly="false"
            labelClass="col-2"
            labelName="フリー入力1"
            size=""
            rows="2"
            v-model="free1"
            :error="''"
        >
        </TextArea>
        <TextArea
            divClass="form-block d-flex m-i-a"
            inputId="free2"
            inputClass="col-9"
            inputName=""
            :readonly="false"
            labelClass="col-2"
            labelName="フリー入力2"
            size=""
            rows="2"
            v-model="free2"
            :error="''"
        >
        </TextArea>
        <TextArea
            divClass="form-block d-flex"
            inputId="free3"
            inputClass="col-9"
            inputName=""
            :readonly="false"
            labelClass="col-2"
            labelName="フリー入力3"
            size=""
            rows="2"
            v-model="free3"
            :error="''"
        >
        </TextArea>
        <TextArea
            divClass="form-block d-flex"
            inputId="note"
            inputClass="col-9"
            inputName=""
            :readonly="false"
            labelClass="col-2 m-r-1"
            labelName="備考"
            size=""
            rows="6"
            v-model="note"
            :error="''"
        >
        </TextArea>
    </div>
    <div class="device-edit-btn btn-group">
        <ButtonBase color="red-magenta" btnClass="" @click="updateCustDevice">更新</ButtonBase>
        <ButtonBase color="cyan" btnClass="" @click="clickEditBackBtn" >戻る</ButtonBase>
    </div>
</template>

<script>
import TextInput from '../base/TextInput.vue';
import TextArea from '../base/TextArea.vue';
import ButtonBase from '@/components/base/ButtonBase.vue';
import {axios, apiHeader, apiUrl} from '@/constants.js';

export default{
    name:"DeviceEdit",
    components:{
        TextInput,
        TextArea,
        ButtonBase,
    },
    props:{
        custDeviceId:Number,
        custDeviceName:String,
        custDeviceNote:String,
        custDeviceFree1:String,
        custDeviceFree2:String,
        custDeviceFree3:String,
        isVisible:{
            type: Boolean,
            default: false
        },
    },
    data(){
        return{
            name: this.custDeviceName,
            note: this.custDeviceNote,
            free1: this.custDeviceFree1,
            free2: this.custDeviceFree2,
            free3: this.custDeviceFree3,
        };
    },
    methods:{
        clickEditBackBtn(){
            this.$emit('update:isVisible', false)
        },
        // 端末情報の更新
        async updateCustDevice(){
            try {
                // 非同期処理を行う例（APIからデータを取得する場合など）
                const response = await axios.post(`${apiUrl}/cust-device-update`,
                {
                    name: this.name,
                    note: this.note,
                    free1: this.free1,
                    free2: this.free2,
                    free3: this.free3,
                    custDeviceId: this.custDeviceId
                },
                {
                    headers:apiHeader
                });
                // 成功ならトークン保存して遷移
                if (response.status == 200){
                    alert('登録に成功しました');
                    window.location.reload();
                }
            }
            catch (error) {
                console.error('Error fetching data:', error);
                alert('エラーが発生しました')
            }
        }
    },
}
</script>
<style>
.btn-group{
  margin-top: 5rem;
  margin-bottom: 10px;
}
.input-block label{
    font-size: 1.05rem !important;
    margin: auto;
}

.input-block  .form-block{
    margin-top: 1rem;
}

</style>

<style scoped>
 .device-edit-btn button{
    margin-inline: 0.5rem;
    margin-top: auto;
    margin-bottom: 0%;
  }
</style>