<!-- CSS修正 -->
<template>
    <div class="login-body">
      <div class="login-container">
          <h1 class="">パスワードのリセット</h1>
          <form class="login-form">
            <div class="text-inputs">
                <TextInput
                divClass="form-group text-form"
                inputId="email"
                inputClass=""
                inputName="email"
                inputType="text"
                :readonly="false"
                labelClass=""
                labelName="メールアドレス"
                size=""
                :error="emailError"
                v-model="email"
                />
            </div>
            <Button size="" color="cyan" id="login" class="submit-btn" @click="click_send_btn">仮パスワード送信</Button>
          </form>
      </div>
    </div>
  </template>

  <script>
  import Button from '@/components/base/ButtonBase.vue';
  import TextInput from '@/components/base/TextInput.vue';
  import {axios,apiUrl} from '@/constants.js';
  import { useRoute, useRouter } from 'vue-router';

  export default {
    name: 'CustLogin',
    components: {
      Button,
      TextInput
    },
    setup(){
    const route = useRoute();
    // 顧客判別用のコード取得
    const cd = route.query.cd;
    const router = useRouter();
    const redirectLogin = () => {
      // クエリパラメータを動的に追加
      router.push({ path: '/login', query: { cd: cd } });
    };
    return {
      redirectLogin,
      cd
    };
  },
    data() {
      return {
        email: '',
        emailError:'',
      };
    },
    methods:{
      // 仮パスワード送信クリック
      click_send_btn()
      {
        if (this.password == '' || this.email == '') {
          this.emailError = this.checkInput(this.email,'メールアドレス')
          return;
        }
        this.sendResetMail()
      },
      // 入力チェック
      checkInput(val,labelName){
        let errorMes=''
        if (val == '') {
            errorMes=labelName+'を入力してください'
          }
        return errorMes
      },
      // ログイン処理
      async sendResetMail(){
        try {
          // 非同期処理を行う例（APIからデータを取得する場合など）
          const response = await axios.post(`${apiUrl}/password-reset`, {
              email:this.email,
              cd: this.cd
            },{
              // ログイン前はトークンなし
              headers:{
                 'Content-Type': 'application/json'
              }
            }
          );
          // 成功ならトークン保存して遷移
          if (response.status == 200){
            alert('パスワードリセットメールを送信しました。');
            this.redirectLogin();
          }
        } catch (error) {
          // 200以外ならメッセージ表示
          console.error('Error fetching data:', error);
          alert('エラーが発生しました')
        }
      }
    }
  }
  </script>