<template>
  <LoggedinBase :selected="1">
    <div class="dashboard-container">
      <TabBase :tabList="tabData" @update-state="updateTabList"></TabBase>
        <div class="d-flex">
          <ControlPanel :deviceList="this.deviceList"
          @update:deviceList="updatedeviceList"></ControlPanel>
          <!-- Tabの値でGPS用のMapとそれ以外で使うコンポーネントを分ける -->
          <GpsMap :deviceList="mapPinList"
          :polylineData="polylineData" :markerData="markerData" />
        </div>
    </div>
  </LoggedinBase>
</template>

<script>
import GpsMap from '@/components/ui/GpsMap.vue';
import ControlPanel from '@/components/ui/ControlPanel.vue';
import LoggedinBase from '@/components/layout/LoggedinBase.vue';
import TabBase from '@/components/layout/TabBase.vue';
import { ref } from 'vue';
import {axios, apiHeader, apiUrl} from '@/constants.js';
// const sharedState = ref('Initial State');

export default {
  name: 'DashbordHome',
  components: {
    GpsMap,
    ControlPanel,
    LoggedinBase,
    TabBase,
  },
  data(){
    return {
      apiUrl:process.env.VUE_APP_API_URL,
      //heightは子コンポーネントに適用されないので、cssを渡す
      tabData: [],
      deviceList: [],
      polylineData: [],
      markerData: [],
    }
  },
  setup() {
    // いらなくなるかも
    const mapPinList = ref([]);
    function onMapPinList(){
      mapPinList.value = this.deviceList
    }
    return {mapPinList, onMapPinList}
  },
  mounted() {
     this.getData();
  },
  methods:{
    async getData(sensorId){
      try {
        this.alertSettingData ={};
        // 非同期処理を行う例（APIからデータを取得する場合など）
        const response = await axios.post(`${apiUrl}/controll-panel`, {
          custDeviceId: this.custDeviceId,
          sensorType: sensorId
        },
        { headers:apiHeader });
        // 成功ならトークン保存して遷移
        if (response.status == 200){
          this.tabData = response.data.data.tabList;
          this.deviceList = response.data.data.deviceList;
        }
      }
      catch (error) {
        console.error('Error fetching data:', error);
        alert('エラーが発生しました')
      }
    },
    // GoogleMapで使うデータの取得
    async getMapData(){
      try {
        await this.onMapPinList();
        // 非同期処理を行う例（APIからデータを取得する場合など）
        const response = await axios.post(`${apiUrl}/map-data`, {
          deviceList:this.mapPinList,
          tabId:Number(document.getElementsByClassName('active-tab')[0].dataset.id)
        },
        { headers:apiHeader });
        // 成功ならトークン保存して遷移
        if (response.status == 200){
          this.polylineData = response.data.data.polylineData;
          this.markerData = response.data.data.markerData;
        }
      }
      catch (error) {
        console.error('Error fetching data:', error);
        alert('エラーが発生しました')
      }
    },
    // 状態管理用
    updateTabList(newState) {
      this.deviceList=[]
      this.getData(Number(newState));
      // sharedState.value = newState;
    },
    // 状態管理&検索処理用
    updatedeviceList(){
      this.getMapData();
    },
  }
}
</script>

<style scoped>
  .dashboard-container {
    width: 98%;
    max-width: 1800px;
    margin: auto;
  }
</style>
