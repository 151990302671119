<template>
  <LoggedinBase :selected="2">
    <div class="device-list-container">
      <TabBase :tabList="tabItems" @update-state="updateState"></TabBase>
      <form>
        <DeviceTable :headrData="headerList" :bodyData="deviceList" :bodyIds="bodyIds"></DeviceTable>
      </form>
    </div>
  </LoggedinBase>
</template>

<script>
import LoggedinBase from '@/components/layout/LoggedinBase.vue';
import TabBase from '@/components/layout/TabBase.vue';
import DeviceTable from '@/components/ui/DeviceTable.vue';
import { ref } from 'vue';
import {axios, apiHeader, apiUrl} from '@/constants.js';

const sharedState = ref(0);

export default {
  name: 'DashbordHome',
  components: {
    LoggedinBase,
    TabBase,
    DeviceTable,
  },
  data(){
    return{
      tabItems: [],
      deviceList:[],
      headerList:[],
      bodyIds:[]
    }
  },
  mounted(){
    if(sharedState.value == 0){
      this.getDeviceList()
    }
  },
  methods:{
    async getDeviceList(){
      try{
          const response = await axios.post(`${apiUrl}/device-list`,{},
            {
              headers: apiHeader
            }
          )
          if (response.status == 200){
            this.deviceList = response.data.data['bodyData'];
            this.headerList = response.data.data['headerData'];
            this.bodyIds = response.data.data['deviceIds'];
            this.tabItems=[{'id':1,'name':'端末'},{'id':2,'name':'アラート履歴'}]
          }
          else{
            alert(response.data.message);
          }
        }
        catch(error){
          console.error(error)
          // とりあえず
          alert('エラーが発生しました。');
        }
    },
    // 選択されたタブの値
    updateState(newState) {
      sharedState.value = newState;
      if(sharedState.value == 1){
        this.getDeviceList()
      }
      // アラート履歴ができたら修正
      else if(sharedState.value == 2){
        this.deviceList = []; //response.data.data['deviceData'];
        this.headerList = [];//response.data.data['headerData'];
        this.bodyIds = [];//response.data.data['deviceIds'];
      }
    }
  }
}
</script>

<style scoped>
</style>
