<template>
  <div class="">
    <div class="card-block">
        <h2 class="">Radix ログイン</h2>
          <TextInput
            divClass=""
            inputId="password"
            inputClass="m-t-05"
            inputName=""
            inputType="password"
            :readonly="false"
            labelClass="d-block"
            labelName="共通パスワード"
            size=""
            :error="passwordError"
            v-model="password"
          >
          </TextInput>
          <div class="m-t-2">
            <Button color="cyan" id="login" class="p-i-1" @click="click_login_btn">ログイン</Button>
          </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/base/ButtonBase.vue';
import TextInput from '@/components/base/TextInput.vue';
import {axios,apiUrl, apiHeaderNotLoginHeader} from '@/constants.js';
import { useRoute, useRouter } from 'vue-router';
import {errorAlertAPI} from '@/utils/utils.js';

export default {
  name: 'RdxLogin',
  components: {
    Button,
    TextInput,
  },
  setup(){
    const route = useRoute();
    // 顧客判別用のコード取得
    const cd = route.query.cd;
    const router = useRouter();
    const redirectPassReset = () => {
      // クエリパラメータを動的に追加
      router.push({ path: '/reset', query: { cd: cd } });
    };
    return {
      cd,
      redirectPassReset
    };
  },
  data() {
    return {
      password:'',
      passwordError:''
    };
  },
  methods:{
    // ログインボタンのクリックイベント
    click_login_btn()
    {
      if (this.password == '') {
        this.passwordError = this.checkInput(this.password,'パスワード')
        return;
      }
      this.postLogin()
    },
    // 入力チェック
    checkInput(val,labelName){
      let errorMes=''
      if (val == '') {
          errorMes=labelName+'を入力してください'
        }
      return errorMes
    },
    // ログイン処理
    async postLogin(){
      try {
        // 非同期処理を行う例（APIからデータを取得する場合など）
        const response = await axios.post(`${apiUrl}/login-rdx`,
          { password: this.password },
          { headers:apiHeaderNotLoginHeader }
        );
        // 成功ならトークン保存して遷移
        if (response.status == 200){
          sessionStorage.setItem('rdx-token', response.data.data);
          // とりあえ端末登録に
          window.location.href="/stockdevice-regist"
        }
      } catch (error) {
        errorAlertAPI(error);
      }
    }
  }
}
</script>

<style>
  .card-block{
    background-color: var(--white);
    border-radius: 8px;
    margin:auto;
    height: 80%;
    width: 70%;
    border: 0.2px solid var(--silver);
    padding-bottom: 1rem;
    margin-top: 4rem;
}
</style>
