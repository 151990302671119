<template>
  <div class="login-body">
    <div class="login-container">
        <h1 class="logo login-logo">Radix IoT</h1>
        <form class="login-form">
          <div class="text-inputs">
          <TextInput
            divClass="form-group text-form"
            inputId="email"
            inputClass=""
            inputName="email"
            inputType="text"
            :readonly="false"
            labelClass="login-label text-label"
            labelName="メールアドレス"
            size=""
            :error="emailError"
            v-model="email"
          >
          </TextInput>
          <TextInput
            divClass="form-group text-form"
            inputId="tmpPassword"
            inputClass=""
            inputName=""
            inputType="password"
            :readonly="false"
            labelClass="login-label text-label"
            labelName="仮パスワード"
            size=""
            :error="passwordError"
            v-model="tmpPassword"
          >
          </TextInput>
          <TextInput
            divClass="form-group text-form"
            inputId="definePassword"
            inputClass=""
            inputName=""
            inputType="password"
            :readonly="false"
            labelClass="login-label text-label"
            labelName="パスワード"
            size=""
            :error="passwordError"
            v-model="definePassword"
          >
          </TextInput>
          <TextInput
            divClass="form-group text-form"
            inputId="confirmPassword"
            inputClass=""
            inputName=""
            inputType="password"
            :readonly="false"
            labelClass="login-label text-label"
            labelName="パスワード確認"
            size=""
            :error="passwordError"
            v-model="confirmPassword"
          >
          </TextInput>

          </div>
          <Button size="" color="cyan" id="login" class="submit-btn" @click="click_regist_btn">パスワード変更</Button>
        </form>
    </div>
  </div>
</template>

<script>
import Button from '@/components/base/ButtonBase.vue';
import TextInput from '@/components/base/TextInput.vue';
import {axios,apiUrl} from '@/constants.js';
import { useRoute ,useRouter} from 'vue-router';


export default {
  name: 'CustUserRegist',
  components: {
    Button,
    TextInput,
  },
  setup(){
    const route = useRoute();
    // 顧客判別用のコード取得
    const cd = route.query.cd;
    const router = useRouter();
    const redirectLogin = () => {
      // クエリパラメータを動的に追加
      router.push({ path: '/login', query: { cd: cd } });
    };
    return {
      cd,
      redirectLogin
    };
  },
  data() {
    return {
      email: '',
      tmpPassword:'',
      emailError:'',
      definePassword:'',
      confirmPassword:'',
      passwordError:'',
      saveCheck:false
    };
  },
  methods:{
    // 変更ボタンのクリックイベント
    click_regist_btn()
    {
      if (this.tmpPassword == '' || this.email == '') {
        this.emailError = this.checkInput(this.email,'メールアドレス')
        // それぞれ変更
        this.passwordError = this.checkInput(this.tmpPassword,'仮パスワード')
        this.passwordError = this.checkInput(this.definePassword,'パスワード')
        this.passwordError = this.checkInput(this.confirmPassword,'パスワード確認')
        return;
      }
      this.registPassword()
    },
    // 入力チェック
    checkInput(val,labelName){
      let errorMes=''
      if (val == '') {
          errorMes=labelName+'を入力してください'
        }
      return errorMes
    },
    // パスワード変更処理
    async registPassword(){
      try {
        // 非同期処理を行う例（APIからデータを取得する場合など）
        const response = await axios.post(`${apiUrl}/define-password`, {
            email:this.email,
            tmpPassword: this.tmpPassword,
            definePassword: this.definePassword,
            confirmPassword: this.confirmPassword,
            cd: this.cd
          },{
            // ログイン前はトークンなし
            headers:{
               'Content-Type': 'application/json'
            }
          }
        );
        // 成功ならトークン保存して遷移
        if (response.status == 200){
          alert(response.data.message)
          this.redirectLogin()
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('エラーが発生しました')
      }
    }
  }
}

</script>

<style>
    .login-body {
        margin: 0;
        padding: 0;
        font-family: Arial, sans-serif;
        display: flex;
        justify-content: center;
    }

    .login-container {
        background-color: var(--white);
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: 100%;
        max-width: 400px;
    }

    .logo {
        color: var(--cyan);
        font-size: 2rem;
        margin-bottom: 20px;
    }

    /* card的なコンポーネント合った方がいいかも */
    .login-form{
      display: flex;
      flex-direction: column;
      padding-inline: 4rem;
    }

    .form-group {
        margin-bottom: 15px;
    }

    /* 入力欄の固有スタイル */
    .form-group input {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 1rem;
    }

    .login-label{
      display: block;
    }

    .text-label{
      text-align: left;
      padding-left: 10%;
    }

    /* チェックボックスの固有スタイル */
    #save{
      margin-left: 1rem;
    }

    #save-label{
      font-size: 0.8rem
    }
    .check-form{
      text-align: left;
      padding-left: 10%;
    }

    /* ボタンの固有スタイル */
    .submit-btn {
        padding: 10px;
    }

    .forgot-password {
        margin-top: 10px;
        color: var(--cyan);
        text-decoration: none;
        font-size: 0.8rem;
        /* padding-left: 2rem; */
        text-align: center;
        padding: 0px;
    }
    .forgot-password:hover {
        text-decoration: underline;
    }
</style>
