<template>
    <div>
        <div class="base-card">
            <h2 class="title-text">顧客・端末紐づけ用ページ</h2>
            <TextInput
                divClass=""
                inputId="password"
                inputClass=""
                inputName="password"
                inputType="password"
                :readonly="false"
                labelClass=""
                labelName=" 共通パスワード"
                size=""
                v-model="password"
            />
            <TextInput
                divClass="m-t-05"
                inputId="lfourId"
                inputClass=""
                inputName="lfourId"
                inputType="text"
                :readonly="false"
                labelClass=""
                labelName="lfour ID"
                size=""
                v-model="lfourId"
            />
            <CameraOCR/>
            <p class="caut-note">※手入力やOCR結果を修正した場合は、端末情報チェックが必要です。</p>
            <h2 class="title-text">登録情報</h2>
            <ul class="info-block">
                <li>顧客No:{{ custData.custNo }}</li>
                <li>顧客名:{{ custData.custName }}</li>
                <li>商品CD:{{ deviceData.prodCode }}</li>
                <li>端末名:{{ deviceData.custDeviceName }}</li>
            </ul>
            <div class="btn-block">
                <ButtonBase class="btn-pi-05 m-t-05" color="red-magenta" @click="insCustDevice">登録</ButtonBase>
            </div>
        </div>
    </div>
  </template>
<script>
import { useRoute } from 'vue-router';
import {axios, apiHeaderNotLoginHeader, apiUrl} from '@/constants.js';
import {errorAlertAPI} from '@/utils/utils.js';
import TextInput from '@/components/base/TextInput.vue';
import ButtonBase from '@/components/base/ButtonBase.vue';
import CameraOCR from '@/components/layout/CameraOCR.vue';

  export default {
    name: 'CustDeviceSetting',
    components:{
        TextInput,
        ButtonBase,
        CameraOCR
    },
    setup(){
        const route = useRoute();
        // 顧客判別用のコード取得
        const cd = route.query.custCd;
        return{
            cd
        }
    },
    mounted(){
        this.searchCustData();
    },
    data(){
        return{
            custData: {},
            lfourId:null,
            deviceData: {},
            password: null,
            photo: null
        }
    },
    methods:{
        // 顧客情報の取得
        async searchCustData(){
            try {
                // 非同期処理を行う例（APIからデータを取得する場合など）
                const response = await axios.post(`${apiUrl}/cust-data`, {
                    cd: this.cd
                },
                {
                    headers:apiHeaderNotLoginHeader
                }
                );
                // 成功ならトークン保存して遷移
                if (response.status == 200){
                    this.custData = response.data.data
                }
            }
            catch (error) {
                console.error('Error fetching data:', error);
                alert('エラーが発生しました')
            }
        },
        // 撮影ボタンクリック
        // 撮影
        // OCR
        // 端末情報チェックのクリック
        // 端末情報チェック
        async searchDeviceData(){
            try {
                // 非同期処理を行う例（APIからデータを取得する場合など）
                const response = await axios.post(`${apiUrl}/device-data-lfour`, {
                    cd: this.cd,
                    lfourId: this.lfourId,
                    password: this.password
                },
                {
                    headers:apiHeaderNotLoginHeader
                }
                );
                // 成功ならトークン保存して遷移
                if (response.status == 200){
                    this.deviceData = response.data.data
                    if(response.data.message){
                        alert(response.data.message);
                    }
                }
            }
            catch (error) {
                errorAlertAPI(error);
            }
        },
        // デバイスの登録クリック
        // デバイスの登録
        async insCustDevice(){
            try {
                if(!this.checkLfourdId()){
                    alert('入力されているlfourIdが、取得している端末情報と一致しません。');
                    return
                }
                // 非同期処理を行う例（APIからデータを取得する場合など）
                const response = await axios.post(`${apiUrl}/require-custdevice`, {
                    custId: this.custData.custId,
                    password: this.password,
                    lfourId: this.lfourId,
                    deviceId: this.deviceData.custDeviceId
                },
                {
                    headers:apiHeaderNotLoginHeader
                }
                );
                // 成功ならトークン保存して遷移
                if (response.status == 200){
                    alert('登録に成功しました');
                    window.location.reload();
                }
            }
            catch (error) {
                console.error('Error fetching data:', error);
                alert('エラーが発生しました')
            }
        },
        // 入力したlfourdIdとdeviceDataのlfourdIdIDの整合性チェック
        checkLfourdId(){
            if(this.lfourId == this.deviceData.lfourId){
                return true
            }
            return false
        },
    }
  };
  </script>

<style scoped>
    .btn-block{
        margin-top: 1rem;
    }
    .btn-pi-05{
        padding-inline: 0.5rem;
    }
    .title-text{
        color: var(--deep-blue);
    }
    .base-card{
        background-color: var(--white);
        border-radius: 8px;
        margin:auto;
        height: 80%;
        width: 90%;
        border: 0.2px solid var(--silver);
        padding-bottom: 3rem;
        margin-top: 1rem;
    }
    .info-block{
        margin-inline: 1rem;
        border: 0.2px solid var(--silver);
        border-radius: 8px;
        list-style: none;
        font-size: 0.8rem;
        padding-inline: 0.3rem;
    }
    .caut-note{
        font-size: 0.7rem;
    }
    .photo-block{
        border: 0.2px solid var(--silver);
        border-radius: 8px;
    }
</style>